import { ArrowBackIosNew } from "@mui/icons-material";
import {
  Button,
  CircularProgress,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import {
  Link,
  NavLink,
  Outlet,
  useNavigate,
  useParams,
} from "react-router-dom";
import { useMount, useUnmount } from "react-use";
import useSurveysStore from "../../../store/surveys";
import { useState } from "react";
import useEditorStore from "../../../store/editor";
import workerize from "workerize-loader!../../../helpers/worker"; // eslint-disable-line import/no-webpack-loader-syntax
import useBaseStore from "../../../store/base";

const ListItemButtonLink = styled(ListItemButton)(({ theme }) => ({
  "&.active": {
    color: theme.palette.primary.main,
    backgroundColor: "rgba(0, 0, 0, 0.04)",
  },
}));

const EditSurvey = () => {
  const [loader, setLoader] = useState(true);
  const { fetchSurveys, setCurrentSurvey, currentSurvey } = useSurveysStore();
  const { setWorker, worker, reset } = useEditorStore();
  const { setSnackbar } = useBaseStore();

  const { id } = useParams();
  const navigate = useNavigate();

  useMount(() => {
    reset();

    const instance = workerize();
    setWorker(instance);

    instance.onmessage = (event) => {
      if (event.data.name === "CompilerError") {
        console.log(event.data);

        setSnackbar({
          open: true,
          type: "error",
          message: event.data.cause
            ? `Cause: ${event.data.cause.name} - ${event.data.cause.value}`
            : event.data.message,
          title: event.data.scope
            ? `${event.data.scope.name}: ${event.data.scope.value}`
            : "",
        });
      }
    };

    (async () => {
      let surveys;

      try {
        surveys = await fetchSurveys();
      } catch (e) {
        console.log(e);

        surveys = [];
      }

      const found = surveys.find((survey) => survey._id === id);

      if (!found) navigate("/");

      setCurrentSurvey(found);

      setLoader(false);
    })();
  });

  useUnmount(() => {
    worker.terminate();
    setCurrentSurvey(null);
  });

  return (
    <Stack
      sx={{
        flexGrow: 1,
      }}
      direction="row"
    >
      {loader ? (
        <CircularProgress thickness={2} size="80px" sx={{ m: "auto" }} />
      ) : (
        <>
          <Stack
            sx={{
              width: "250px",
              backgroundColor: "grey.50",
              borderRadius: "4px",
              flexShrink: 0,
            }}
          >
            <Button
              startIcon={<ArrowBackIosNew />}
              component={Link}
              to="/"
              sx={{ alignSelf: "start" }}
            >
              Back
            </Button>

            <List>
              <ListItem disablePadding>
                <ListItemButtonLink component={NavLink} to="" end>
                  <ListItemText primary="General" />
                </ListItemButtonLink>
              </ListItem>

              <ListItem disablePadding>
                <ListItemButtonLink component={NavLink} to="editor">
                  <ListItemText primary="Editor" />
                </ListItemButtonLink>
              </ListItem>

              {process.env.REACT_APP_STAGE !== "production" && (
                <ListItem disablePadding>
                  <ListItemButtonLink component={NavLink} to="translations">
                    <ListItemText primary="Translations" />
                  </ListItemButtonLink>
                </ListItem>
              )}

              <ListItem disablePadding>
                <ListItemButtonLink component={NavLink} to="publish">
                  <ListItemText primary="Publish" />
                </ListItemButtonLink>
              </ListItem>
            </List>
          </Stack>

          <Stack sx={{ flexGrow: 1, px: 1 }}>
            <Stack
              direction="row"
              gap={1}
              alignItems="center"
              justifyContent="center"
            >
              <Typography variant="body1" sx={{ fontWeight: 600 }}>
                Survey Name:
              </Typography>

              <Typography variant="body1" component="span">
                {currentSurvey?.name}
              </Typography>
            </Stack>

            <Divider sx={{ my: 1 }} />

            <Outlet />
          </Stack>
        </>
      )}
    </Stack>
  );
};

export default EditSurvey;

import { Divider, Stack, Typography } from "@mui/material";
import TranslateContent from "./TranslateContent";
import ExportTranslations from "./exportTranslations/ExportTranslations";
import ImportTranslations from "./ImportTranslations";

const Translations = () => {
  return (
    <Stack
      sx={{
        flexGrow: 1,
        pt: 4,
      }}
    >
      <Typography
        variant="body1"
        component="a"
        href={process.env.REACT_APP_TRANSLATIONS_DRIVE}
        target="_blank"
        sx={{
          alignSelf: "center",
          textAlign: "center",
          mb: 4,
          color: "primary.main",
        }}
      >
        Translations Drive
      </Typography>

      <Stack
        sx={{
          flexGrow: 1,
        }}
        gap={4}
      >
        <TranslateContent />

        <Divider />

        <ExportTranslations />

        <Divider />

        <ImportTranslations />
      </Stack>
    </Stack>
  );
};

export default Translations;

import AuthGuard from "./AuthGuard";
import Surveys from "../views/surveys/Surveys";
import CreateSurvey from "../views/surveys/CreateSurvey";
import EditSurvey from "../views/surveys/edit-survey/EditSurvey";
import General from "../views/surveys/edit-survey/General";
import Editor from "../views/surveys/edit-survey/editor/Editor";
import SheetId from "../views/surveys/edit-survey/editor/SheetId";
import { Navigate } from "react-router-dom";
import Tabs from "../views/surveys/edit-survey/editor/tabs/Tabs";
import TabItem from "../views/surveys/edit-survey/editor/tab-item/TabItem";
import CodeEditor from "../views/surveys/edit-survey/editor/code-editor/CodeEditor";
import Translations from "../views/surveys/edit-survey/translations/Translations";
import Localisation from "../views/surveys/edit-survey/localisation/Localisation";

const routes = [
  {
    path: "/surveys",
    element: (
      <AuthGuard requiresAuth allowedRoles={["admin"]}>
        <Surveys />
      </AuthGuard>
    ),
  },
  {
    path: "/surveys/create",
    element: (
      <AuthGuard requiresAuth allowedRoles={["admin"]}>
        <CreateSurvey />
      </AuthGuard>
    ),
  },
  {
    path: "/surveys/:id",
    element: (
      <AuthGuard requiresAuth allowedRoles={["admin"]}>
        <EditSurvey />
      </AuthGuard>
    ),
    children: [
      {
        index: true,
        element: <General />,
      },
      {
        path: "editor/sheetId",
        element: <SheetId />,
      },
      {
        path: "editor",
        element: <Editor />,
        children: [
          {
            element: <Navigate to="questions" replace={true} />,
            index: true,
          },
          {
            path: ":tab",
            element: <Tabs />,
          },
          {
            path: ":tab/:itemId",
            element: <TabItem />,
          },
          {
            path: ":tab/:itemId/code-editor",
            element: <CodeEditor />,
          },
        ],
      },
      ...(process.env.REACT_APP_STAGE !== "production"
        ? [
            {
              path: "translations",
              element: <Translations />,
            },
          ]
        : [{}]),
      {
        path: "publish",
        element: <Localisation />,
      },
    ],
  },
];

export default routes;

import {
  Alert,
  AlertTitle,
  Box,
  Chip,
  Dialog,
  FormControl,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { AVAILABLE_LOCALES } from "../../../../helpers/constants";
import { useEffect, useMemo, useState } from "react";
import { LoadingButton } from "@mui/lab";
import useSurveysStore from "../../../../store/surveys";
import useBaseStore from "../../../../store/base";
import { API } from "aws-amplify";
import getJwt from "../../../../helpers/getJwt";

const ImportTranslations = () => {
  const currentSurvey = useSurveysStore((state) => state.currentSurvey);
  const setSnackbar = useBaseStore((state) => state.setSnackbar);

  const [dialog, setDialog] = useState(false);
  const [locale, setLocale] = useState("");
  const [loader, setLoader] = useState(false);
  const [spreadsheetId, setSpreadsheetId] = useState("");

  const localeValue = useMemo(() => {
    return AVAILABLE_LOCALES.find((l) => l.id === locale)?.value || "";
  }, [locale]);

  useEffect(() => {
    if (!dialog) setSpreadsheetId("");
  }, [dialog]);

  const handleImport = async () => {
    try {
      setLoader(true);

      const jwt = await getJwt();

      const translations = await API.post("editor", `/translations/importer`, {
        body: {
          locale,
          spreadsheetId,
          survey: currentSurvey.name,
        },
        headers: { Authorization: `Bearer ${jwt}` },
      });

      await API.put("translations", `/localiser`, {
        body: {
          source: currentSurvey.name,
          locale,
          values: translations,
        },
        headers: { Authorization: `Bearer ${jwt}` },
      });

      setSnackbar({
        open: true,
        type: "success",
        message: "Translations were imported",
      });
    } catch (e) {
      if (e?.response?.status === 400) {
        const { question, rowNumber } = e.response.data.context;

        setSnackbar({
          open: true,
          type: "error",
          message: `There was an error on ${e.response.data.sheet} sheet at row ${rowNumber} (question ${question})`,
        });
      } else {
        console.error(e);

        setSnackbar({
          open: true,
          type: "error",
          message: "Error while importing translations",
        });
      }
    }

    setLoader(false);
    setDialog(false);
  };

  return (
    <Stack sx={{ width: "500px", alignSelf: "center" }} gap={2}>
      <Stack sx={{ alignItems: "center" }} direction="row" gap={2}>
        <FormControl size="small" sx={{ flexGrow: 1 }}>
          <InputLabel id="demo-simple-select-label">Locale</InputLabel>

          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={locale}
            label="Locale"
            renderValue={(selected) =>
              AVAILABLE_LOCALES.find((locale) => locale.id === selected)?.value
            }
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: "300px",
                },
              },
            }}
            onChange={(event) => setLocale(event.target.value)}
          >
            {AVAILABLE_LOCALES.map((locale) => (
              <MenuItem
                key={locale.id}
                value={locale.id}
                sx={{ display: "flex", alignItems: "center", gap: "4px" }}
              >
                {locale.value}

                <Box component="span" sx={{ fontWeight: "bold" }}>
                  ({locale.id})
                </Box>
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <LoadingButton
          variant="outlined"
          sx={{ flexShrink: 0, width: "220px" }}
          loading={loader}
          disabled={!locale}
          onClick={() => setDialog(true)}
        >
          3. Import Translations
        </LoadingButton>
      </Stack>

      <Dialog onClose={() => setDialog(false)} open={dialog}>
        <Stack sx={{ width: "600px", padding: 2 }} gap={2}>
          {!loader && (
            <>
              <Alert severity="warning">
                <AlertTitle>Warning</AlertTitle>
                Make sure you provide a translation spreadsheet correspoding to
                the selected locale.
              </Alert>

              <Stack direction="row" gap={1} sx={{ alignItems: "center" }}>
                <Typography variant="body2" sx={{ fontWeight: 500 }}>
                  Selected Locales:
                </Typography>

                <Chip label={localeValue} />
              </Stack>

              <TextField
                label="Spreadsheet ID"
                variant="outlined"
                value={spreadsheetId}
                size="small"
                onChange={(e) => setSpreadsheetId(e.target.value)}
              />

              <LoadingButton
                sx={{ mt: 2 }}
                loading={loader}
                variant="outlined"
                disabled={!spreadsheetId}
                onClick={handleImport}
              >
                Import
              </LoadingButton>
            </>
          )}

          {loader && (
            <>
              <Alert severity="info" sx={{ fontWeight: "bold" }}>
                Please wait while spreadsheet is being imported. It may take a
                minute.
              </Alert>

              <LinearProgress />
            </>
          )}
        </Stack>
      </Dialog>

      <Alert severity="info">
        This will import translations for a selected language from a review
        spreadsheet.
      </Alert>
    </Stack>
  );
};

export default ImportTranslations;

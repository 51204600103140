import {
  Alert,
  AlertTitle,
  Box,
  Checkbox,
  Chip,
  Dialog,
  FormControl,
  InputLabel,
  LinearProgress,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { AVAILABLE_LOCALES } from "../../../../helpers/constants";
import { LoadingButton } from "@mui/lab";
import { useMemo, useState } from "react";
import useBaseStore from "../../../../store/base";
import getJwt from "../../../../helpers/getJwt";
import { API } from "aws-amplify";
import useSurveysStore from "../../../../store/surveys";
import { map, chunk, flatten } from "lodash";
import extractor from "../../../../helpers/extractor";

const TranslateContent = () => {
  const currentSurvey = useSurveysStore((state) => state.currentSurvey);
  const setSnackbar = useBaseStore((state) => state.setSnackbar);

  const [locales, setLocales] = useState([]);
  const [loader, setLoader] = useState(false);
  const [dialog, setDialog] = useState(false);

  const localeValues = useMemo(() => {
    return locales.map(
      (locale) => AVAILABLE_LOCALES.find((l) => l.id === locale).value
    );
  }, [locales]);

  const uploadSurveyContentToTranslationsDB = async () => {
    const jwt = await getJwt();

    const { pages, questions } = await API.get(
      "surveys",
      `/surveys/name/${currentSurvey.name}/exporter`
    ); // get survey data

    const result = map(extractor({ pages, questions }), (item) => ({
      content: item.value,
    }));
    // map the result to the appropriate format.
    // only pages and questions are relevant for translations

    await API.put("translations", "/importer", {
      body: {
        locale: "en",
        source: currentSurvey.name,
        values: result,
      },
      headers: { Authorization: `Bearer ${jwt}` },
    }); // upload the content in the translations DB
  };

  const translateSurveyContent = async () => {
    const jwt = await getJwt();

    const chunks = chunk(locales, 4);

    const requests = chunks.map((chunk) =>
      API.post("editor", `/translations/translator`, {
        body: {
          locales: chunk,
          source: currentSurvey.name,
        },
        headers: { Authorization: `Bearer ${jwt}` },
      })
    );

    return Promise.all(requests);
  };

  const uploadTranslations = async (translations) => {
    const jwt = await getJwt();

    for (const translationObj of translations) {
      await API.put("translations", "/localiser", {
        body: {
          locale: translationObj.locale,
          source: currentSurvey.name,
          values: translationObj.content,
        },
        headers: { Authorization: `Bearer ${jwt}` },
      });
    }
  };

  const handleTranslate = async () => {
    setLoader(true);

    try {
      await uploadSurveyContentToTranslationsDB();

      const translations = flatten(await translateSurveyContent());

      await uploadTranslations(translations);

      setSnackbar({
        open: true,
        type: "success",
        message: "Content was translated",
      });
    } catch (e) {
      console.error(e);

      setSnackbar({
        open: true,
        type: "error",
        message: "Error while translating content",
      });
    }

    setLoader(false);
    setDialog(false);
  };

  return (
    <Stack sx={{ width: "500px", alignSelf: "center" }} gap={2}>
      <Stack sx={{ alignItems: "center" }} direction="row" gap={2}>
        <FormControl size="small" sx={{ flexGrow: 1 }}>
          <InputLabel>Locales</InputLabel>

          <Select
            multiple
            value={locales}
            onChange={(e) => setLocales(e.target.value)}
            label="Locales"
            renderValue={(selected) =>
              selected
                .map(
                  (locale) =>
                    AVAILABLE_LOCALES.find((l) => l.id === locale).value
                )
                .join(", ")
            }
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: "300px",
                },
              },
            }}
          >
            {AVAILABLE_LOCALES.map((locale) => (
              <MenuItem key={locale.id} value={locale.id}>
                <Checkbox checked={locales.includes(locale.id)} />
                <ListItemText
                  primary={
                    <>
                      {locale.value}{" "}
                      <Box component="span" sx={{ fontWeight: "bold" }}>
                        ({locale.id})
                      </Box>
                    </>
                  }
                />
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <LoadingButton
          variant="outlined"
          sx={{ flexShrink: 0, width: "220px" }}
          loading={loader}
          disabled={!locales.length}
          onClick={() => setDialog(true)}
        >
          1. Translate content
        </LoadingButton>

        <Dialog onClose={() => setDialog(false)} open={dialog}>
          <Stack sx={{ width: "600px", padding: 2 }} gap={2}>
            {!loader && (
              <>
                <Alert severity="warning">
                  <AlertTitle>Warning</AlertTitle>
                  Make sure the survey's content is <strong>frozen</strong>
                </Alert>

                <Alert severity="warning">
                  <AlertTitle>Warning</AlertTitle>
                  <strong>Deploy</strong> latest content from the editor tab is{" "}
                  <strong>required</strong>
                </Alert>

                <Typography variant="body2" sx={{ fontWeight: 500 }}>
                  Selected Locales:
                </Typography>

                <Stack direction="row" gap={1} sx={{ flexWrap: "wrap" }}>
                  {localeValues.map((locale) => (
                    <Chip key={locale} label={locale} />
                  ))}
                </Stack>

                <LoadingButton
                  loading={loader}
                  variant="outlined"
                  onClick={handleTranslate}
                >
                  Translate
                </LoadingButton>
              </>
            )}

            {loader && (
              <>
                <Alert severity="info" sx={{ fontWeight: "bold" }}>
                  Please wait while content is being translated. It may take a
                  minute.
                </Alert>

                <LinearProgress />
              </>
            )}
          </Stack>
        </Dialog>
      </Stack>

      <Alert severity="info">
        This will translate new content found in this survey. Please make sure
        the latest content has been deployed.
      </Alert>
    </Stack>
  );
};

export default TranslateContent;
